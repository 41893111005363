import(/* webpackMode: "eager" */ "/app/node_modules/@little-worker/hestia/dist/components/Accordion/Accordion.cjs");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@little-worker/hestia/dist/components/CounterGroup/CounterGroup.cjs");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@little-worker/hestia/dist/components/Toast/ToastContainer.cjs");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/react-aria-components/dist/main.js");
;
import(/* webpackMode: "eager" */ "/app/src/assets/images/not-found.png");
